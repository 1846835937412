export default {

  // 头部+
  headerDataFunc (state, data) {
    state.headerData = data;
  },

  // intro1
  introDataFunc (state, data) {
    state.introData = data;
  },
  // intro2
  getSponsorsList (state, data) {
    state.sponsors = data;
  },
  // intro3
  getPartnerList (state, data) {
    state.partners = data;
  },
  // intro4
  ProjectSingle (state, data) {
    // console.log("ProjectSingle", data);
    state.project_single = data || {};
  },


  allProjectDetail (state, data) {
    state.allProjectDetail = data;
  },
  postCommentData (state, data) {
    state.postComment = data;
  },
  programType (state, data) {
    state.package_type = data;
  },



  commentLastScoreData (state, data) {
    state.commentLastScore = data;
  },
  getQuestionData (state, data) {
    state.questionnaire = data;
  },
  getCertificateData (state, data) {
    state.certificate = data;
  },
  getResultData (state, data) {
    state.result = data;
  },


  // 改变注册的状态
  changeRegister (state, data) {
    state.is_register = data;
  },

  // 获取所有行程列表
  getAllProgramList (state, data) {
    state.all_program = data;
    let newArr = Object.keys(data);
    for (var i = 0; i < newArr.length; i++) {
      if (new Date(newArr[i].split('[')[0]).toDateString() == new Date().toDateString()) {
        return state.index = state.tabIndex
      } else if (new Date(newArr[i].split('[')[0]).getTime() - new Date().getTime() > 0) {
        return state.index = state.tabIndex
      } else {
        state.index = state.tabIndex
        // state.tabIndex=0
      }
    }
  },

  changeIndex (state, data) {
    (data == 1) ? state.index++ : state.index--;
    if(state.index>=0){
      state.tabIndex = state.index
    }
  },

  checkIndex(state){
    state.tabIndex=0;
    state.index=0;
  },

  mySingleProgram (state, data) {
    state.single_schedule = data;
  },

  // 添加到我的日程--改变状态
  projectAddProgram (state, data) {
    data.my_schedule = true;
  },


  /**
   * plantobuy
  */
  // 判断显示早鸟时间还是当前时间
  isShowBirdTimeFunc (item) {
    if (item.bird_start_time && item.bird_end_time) {
      // 两个时间同时存在并且当前时间在范围内
      let now = new Date().getTime();
      let start = new Date(item.bird_start_time).getTime();
      let end = new Date(item.bird_end_time).getTime();
      if (now > start && now < end) {
        vm.$set(item, "is_show_bird_price", true);
      }
    } else {
      vm.$set(item, "is_show_bird_price", false);
    }
  },

  // 支付弹框的内容显示
  payModalContent (state, data) {
    let { is_refund, refund_instruction } = data;
    state.payModalContent = is_refund ? refund_instruction : vm.$t('Events.cec@please_be_reminded_t')
  },

  //  获取单一行程列表
  // getSingleProgram (state, data) {
  //   let { program_list, time_zone } = data;
  //   Object.keys(program_list).forEach((key) => {
  //     // 单一价格设置是否显示早鸟价格
  //     let oneProgram = program_list[key]["single_ticket"];
  //     oneProgram.forEach((item) => {
  //       vm.$set(item, "time_zone", time_zone);
  //       vm.$set(item, "isChecked", false);
  //       // this.commit('isShowBirdTimeFunc', item)
  //       if (item.bird_start_time && item.bird_end_time) {
  //         // 两个时间同时存在并且当前时间在范围内
  //         let now = new Date().getTime();
  //         let start = new Date(item.bird_start_time).getTime();
  //         let end = new Date(item.bird_end_time).getTime();
  //         if (now > start && now < end) {
  //           vm.$set(item, "is_show_bird_price", true);
  //         }
  //       } else {
  //         vm.$set(item, "is_show_bird_price", false);
  //       }
  //     });
  //     // 全天价格显示是否显示早鸟价格
  //     let dayProgram = program_list[key]["day_ticket"];
  //     vm.$set(dayProgram, "isDayChecked", false);
  //     console.log('dayProgram', dayProgram);
  //     if (dayProgram.bird_start_time && dayProgram.bird_end_time) {
  //       // 两个时间同时存在并且当前时间在范围内
  //       let now = new Date().getTime();
  //       let start = new Date(dayProgram.bird_start_time).getTime();
  //       let end = new Date(dayProgram.bird_end_time).getTime();
  //       if (now > start && now < end) {
  //         console.log('mutation', now, start, end);
  //         vm.$set(dayProgram, "is_show_bird_price", true);
  //       }
  //     } else {
  //       vm.$set(dayProgram, "is_show_bird_price", false);
  //     }
  //   });
  //   state.single_program = program_list;
  // },

  //  获取full_package
  // getFullPackage (state, data) {
  //   let fullPackage = data;
  //   if (fullPackage.bird_start_time && fullPackage.bird_end_time) {
  //     // 两个时间同时存在并且当前时间在范围内
  //     let now = new Date().getTime();
  //     let start = new Date(fullPackage.bird_start_time).getTime();
  //     let end = new Date(fullPackage.bird_end_time).getTime();
  //     if (now > start && now < end) {
  //       vm.$set(fullPackage, "is_show_bird_price", true);
  //     }
  //   } else {
  //     vm.$set(fullPackage, "is_show_bird_price", false);
  //   }
  //   state.full_package = fullPackage;
  // },
  // 获取online_ticket
  // getOnlineTicket (state, data) {
  //   let onlinePackage = data;
  //   if (onlinePackage.bird_start_time && onlinePackage.bird_end_time) {
  //     // 两个时间同时存在并且当前时间在范围内
  //     let now = new Date().getTime();
  //     let start = new Date(onlinePackage.bird_start_time).getTime();
  //     let end = new Date(onlinePackage.bird_end_time).getTime();
  //     if (now > start && now < end) {
  //       vm.$set(onlinePackage, "is_show_bird_price", true);
  //     }
  //   } else {
  //     vm.$set(onlinePackage, "is_show_bird_price", false);
  //   }
  //   state.online_ticket = onlinePackage;
  // },
  // 获取打包套餐
  getPackageProgram (state, data) {
    // console.log('mutation', data);
    let { combination, time_zone } = data;

    combination.forEach((com) => {
      // 为每条打包数据添加是否显示早鸟价格
      if (com.bird_start_time && com.bird_end_time) {
        let now = new Date().getTime();
        let start = new Date(com.bird_start_time).getTime();
        let end = new Date(com.bird_end_time).getTime();
        if (now > start && now < end) {
          // 显示早鸟价格
          vm.$set(com, "is_show_bird_price", true);
          // vm.$set(com, 'countdown', '');
        } else if (start > now) {
          // 显示倒计时
          vm.$set(com, 'isShowCountDown', true)
        }
      } else {
        vm.$set(com, "is_show_bird_price", false);

      }

      // 为每条行程添加时区
      com.program.forEach((program) => {
        vm.$set(program, "time_zone", time_zone);
      });
      this.commit('isShowBirdTimeFunc', com)
    });
    // console.log('combination', combination);
    state.package_program = combination;
  },

  // 勾选单一价格
  checkSinglePrice (state, item) {
    let type = vm.$route.query.type
    if (!item.is_buy && state.is_register) {
      item.isChecked = !item.isChecked;
      if (item.isChecked) {
        // 勾选的item
        var params = {
          // package_type: item.package_type,
          package_type: item.package_type,
          model_type: item.model_type,
          model_id: item.model_id,
          program_id: item.program_id,
        };
        state.checkedList.push(params);
      } else {
        state.checkedList = state.checkedList.filter(
          (v) => item.program_id != v.program_id
        );
      }
    } else {
      if (item.is_buy) vm.$message.warning(vm.$t('Events.2ea@you_have_purchased'));
      if (!state.is_register) vm.$message.warning(vm.$t('Events.7a9@please_register_firs'));
    }
  },

  // 置空勾选的数据
  clearCheckArray (state) {
    state.checkedList = [];
    state.checkedPackageList = [];
    state.checkAllPassList = [];
    state.buyPackageList = [];
  },
  // 勾选当天价格
  checkDayPrice (state, item) {
    if (!item.is_buy && state.is_register && item.is_edit) {
      item.isDayChecked = !item.isDayChecked;
      if (item.isDayChecked) {
        // 勾选的item
        state.checkedPackageList.push(item.setmeal_id);
      } else {
        state.checkedPackageList = state.checkedPackageList.filter(
          (v) => item.setmeal_id != v
        );
      }
    } else {
      if (item.is_buy) vm.$message.warning(vm.$t('Events.2ea@you_have_purchased'));
      if (!state.is_register) vm.$message.warning(vm.$t('Events.7a9@please_register_firs'));
    }
  },

  // planToBuy-package
  changePackageList (state, item) {
    state.buyPackageList.push(item.setmeal_id);
  },

  // 支付online和allpass--改变checkAllPassList值
  payOnlineAllPass (state, item) {
    if (state.checkAllPassList.indexOf(item) >= 0) return;
    state.checkAllPassList.push(item)
  },

  // 取消弹框
  cancelPay (state) {
    let planData = state.single_program;
    Object.keys(planData).forEach(key => {
      // 置空所有的day_ticket
      let day_ticket = planData[key]['day_ticket'];
      if (day_ticket && day_ticket.isDayChecked) {
        day_ticket.isDayChecked = false;
      }

      // 置空所有的单一行程
      let single_ticket = planData[key]['single_ticket'];
      if (single_ticket) {
        single_ticket.forEach(program => {
          if (program.isChecked) {
            program.isChecked = false;
          }
        })
      }
    })
    state.checkedList = []; //单一行程置空
    state.checkedPackageList = []; //套餐置空
    state.checkAllPassList = []; //allpass
    vm.$bvModal.hide('payConfirm');

  },

  // attendees
  getAttendeesList (state, data) {
    state.attendeesList = [...state.attendeesList, ...data]
  },


  // COC
  getCocList (state, data) {
    state.cocList = [...state.cocList, ...data]
  },

  // getTablineData
  getTabList (state, [data, isScroll, page]) {
    if (page == 1 || !page) state.tabListData = []
    if (!isScroll) state.tabListData = data;
    if (!data) return
    if (isScroll) state.tabListData = [...state.tabListData, ...data];
  },
  changeCocAddressDis (state, data) {
    state.cocAddressDis = data;
  },
  changeAddressDis (state, data) {
    state.addressDis = data;
  },


}

