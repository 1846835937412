(function () {
  var calc = function () {
    var docElement = document.documentElement
    var clientWidthValue = docElement.clientWidth
    var innerWidthValue = window.innerWidth
    // console.log('pc', innerWidthValue);
    if (innerWidthValue < 768) {
      docElement.style.fontSize = 16 / 375 * clientWidthValue + 'px'
    } else {
      // docElement.style.fontSize = '18px'
    }
  }
  calc();
  window.addEventListener('resize', calc);
})()