// import getters from './getters'
// import actions from './actions'
import mutations from './mutations'

const state = {
  curentUserInfo: {},

  ChatList: [],

  meetingMember: [],

}

export default {
  // namespaced: true,
  state,
  mutations
}
