export default {
  // 获取我的行程（4方块中的1,3,4）
  async projectProgram ({ commit }, pramaData) {
    let [data, ajaxType] = pramaData;
    let res = await vm.$http[ajaxType](data);
    if (data.type == 1 || data.type == 2 || data.type == 3) {
      commit('getAllProgramList', res.data.programs)
      commit('changeRegister', res.data.is_register)
    } else {
      commit('mySingleProgram', res.data.programs)
    }
  },

  // 添加到我的行程
  async projectAddProgram ({ commit }, [{ v, id }, type]) {
    let typeId = {
      1: 'project_id',
      2: 'event_id',
      3: 'course_id',
      4: 'meeting_id',
    }
    let ajaxType1 = {
      1: 'projectProgram',
      2: 'eventProgram',
      3: 'courseProgram',
      4: 'meetingProgram',
    }
    let ajaxType = {
      1: 'projectAddProgram',
      2: 'eventAddProgram',
      3: 'courseAddProgram',
      4: 'meetingAddProgram',
    }
    // 添加接口
    let res = await vm.$http[ajaxType[type]]({
      my_program_id: v.my_program_id,
      my_program_model_type: v.my_program_model_type,
    })
    if (res.status == 200) {
      commit('projectAddProgram', v)
      // 重新刷新接口
      let res = await vm.$http[ajaxType1[type]]({
        [typeId[type]]: id,
        type: 4,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      // 重新我的日程数据
      let res1 = await vm.$http[ajaxType1[type]]({
        [typeId[type]]: id,
        type: 2,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      commit('getAllProgramList', res1.data.programs)
      commit('mySingleProgram', res.data.programs)
    }
  },

  // plantobuy
  async _packageList ({ commit }, pramaData) {
    let [data, ajaxType] = pramaData;
    let res = await vm.$http[ajaxType](data);

    // 改变注册状态
    commit('changeRegister', res.data.is_register);

    // 支付弹框的显示内容
    commit('payModalContent', {
      is_refund: res.data.is_refund,
      refund_instruction: res.data.refund_instruction
    });

    // 获取单一行程列表
    // commit('getSingleProgram', {
    //   program_list: res.data.fixed_package.one_day,
    //   time_zone: res.data.time_zone,
    // })

    // 获取full_package
    // commit('getFullPackage', res.data.fixed_package.full_ticket)

    // 获取online_ticket
    // commit('getOnlineTicket', res.data.fixed_package.online_ticket);

    // 获取打包套餐
    commit('getPackageProgram', {
      combination: res.data.custom_package,
      time_zone: res.data.time_zone
    });

  },


  // 选项卡切换
  async getProjectTabData ({ commit }, pramaData) {
    let [data, ajaxType] = pramaData;
    let res = await vm.$http[ajaxType](data);
    let isScroll = (data.type == 1 || data.type == 5) ? true : false;
    commit('changeCocAddressDis', false);
    commit('changeAddressDis', false);
    commit('getTabList', [res.data.data, isScroll, data.page]);

    if (Array.isArray(res.data)) {
      if (data.type == 1) return commit('changeCocAddressDis', true)
      if (data.type == 5) return commit('changeAddressDis', true)
      return;
    }
  },

  // 生成订单
  async paySingle ({ commit }, pramaData) {
    let [data, ajaxType, type] = pramaData;
    let res = await vm.$http[ajaxType](data);
    // if (res.status == 200) {
    //   console.log(vm.$router);
    //   if (res.data.is_jump == 1) {
    //     vm.$router.push({
    //       path: `/PayMoney`,
    //       query: {
    //         type: 4,
    //         id: res.data.order_id,
    //       },
    //     });
    //   } else if (res.data.is_jump == 0) {
    //     vm.$router.push({
    //       path: `/PaySuccess`,
    //       query: {
    //         typeStatus: 1
    //       }
    //     })
    //   }
    //   commit('clearCheckArray');
    // }
    vm.$bvModal.hide('payConfirm');

  }
}
