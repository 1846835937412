export default {
  bindUser (state, userInfo) {
    state.curentUserInfo = userInfo;
  },

  // 存储一条消息
  SaveChatData (state, chatData) {
    // 判断---如果该信息是已经存在的用户信息---就不存储
    let isSaveUser = state.meetingMember.some(item => item.send_user_id == chatData.send_user_id);
    if (!isSaveUser) {
      state.meetingMember.push(chatData);//不存在该信息就存进去
    }

    let currentUser = state.curentUserInfo;
    // 进入会议判断--tag
    if (chatData.msg_type == 8) {
      chatData.msgTag = 'tag';
      chatData.nick_name = '';
      chatData.send_time = '';
      let result = state.ChatList.some(item => item.order == chatData.order);
      if (!result && !isSaveUser) state.ChatList.push(chatData);
      return;
    };

    // 自己发的信息添加tag
    if (currentUser.user_id == chatData.send_user_id) chatData.msgTag = 'self';

    // 没有该条信息，则存储该信息
    let result = state.ChatList.some(item => item.order == chatData.order);
    if (!result) state.ChatList.push(chatData);
  },

  // 存储聊天记录
  saveChatList (state, paramsList) {
    let currentUser = JSON.parse(localStorage.getItem('userInfo'));
    // 进入会议判断--tag
    paramsList.forEach(item => {
      if (item.msg_type == 8) {
        item.msgTag = 'tag';
        item.nick_name = '';
        item.send_time = '';
        return;
      };
      if (currentUser.user_id == item.send_user_id) item.msgTag = 'self';
    })
    if (paramsList.length) {
      state.ChatList = [...paramsList, ...state.ChatList];
    };
  },

  clearChatList (state) {
    state.ChatList = [];
  }

}

